.App {
  display: flex;
  height: 100vh;
}

.split {
  flex: 1; /* Split equally */
  overflow: auto; /* Add scroll if needed */
}

.navbar {
  padding: 10px;
  background-color: #f0f0f0; /* Example background color */
}

.logo {
  height: 50px; /* Adjust as needed */
}

.dropdown-container {
  margin: 20px;
}

.dropdown-container label {
  display: block;
  margin-top: 10px;
}

.left {
  background-color: #f8f8f8;
}

.right {
  background-color: #e8e8e8;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.field input[type="checkbox"] {
  vertical-align: middle;
  margin-right: 0.5em;
}

.titles-container {
  display: flex; /* This will layout its children (the two divs) in a row */
  justify-content: start; /* This aligns items to the start of the container */
  gap: 10px; /* Optional: adds some space between the two divs */
}



