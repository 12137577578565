/* font styles */
:root {
    --font-primary: "instrument sans", sans-serif;
}

/* color styles */
:root {
    --color-primary: #1861DD;
    --color-primary-hover: #1B4EB2;
    --color-primary-light: #DAEEFF;
    --color-primary-selection: #EFF8FF;
    --color-font: #363636;
    --overlay-shade1: rgba(0, 0, 0, .60);
    --white: #fff;
    --error: #F04438;
}



/* font styles */
.title.is-4 {
    /* refer to h1 in design */
    font-family: var(--font-primary);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
}

.title.is-5 {
    /* refer to h2 in design */
    font-family: var(--font-primary);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
}

.label, th, .switch[type=checkbox]+label {
    /* refer to body-bold in design */
    font-family: var(--font-primary);
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px !important; /* 142.857% */
}

.input, p, td, .select, .textarea, body, .tabs, span {
    /* refer to body in deisgn */
    /* .select is for the select dropdown componenet */
    font-family: var(--font-primary);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

/* font label for buttons */
.button, .file-label {
    /* refer to button-label in design*/
    font-family: var(--font-primary);
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}



/* component colors */
.button.is-primary, .button.is-link {
    background-color: var(--color-primary);
}

.button.is-primary:hover, .button.is-link:hover {
    background-color: var(--color-primary-hover);
    /* background-color: #000; */
}

.button.is-success, .button.is-success[disabled] {
    background-color: var(--color-primary);
    border-color: rgba(0, 0, 0, 0);
}

.button.is-success:hover {
    background-color: var(--color-primary-hover);
}

.button.is-secondary {
    border-color: var(--color-primary);
    color: var(--color-primary);
}

.button.is-secondary:hover {
    color: var(--color-primary-hover);
    border-color: var(--color-primary-hover);
    background-color: var(--color-primary-light);
}

tr.is-selected {
    background-color: var(--color-primary-light) !important;
    color: var(--color-font) !important;
}

.modal-background {
    /* This is the overlay between the screen and modal*/
    background-color: var(--overlay-shade1);
}

.modal-content {
    /* Bakcground of the modal window */
    background-color: var(--white);
}

.modal-close::after, .modal-close::before {
    /* color for the cross icon */
    background-color: var(--color-primary);
}

.modal-close:hover {
    /* bacground color for the cross icon when hovered */
    background-color: rgba(0, 0, 0, .10);
}

.switch[type=checkbox].is-info:checked+label::before,
.switch[type=checkbox].is-info:checked+label:before {
    /* background color for the switch toggle when turned on */
    background: var(--color-primary) !important;
}

.button.is-small.icon-button:hover.icon-button:hover {
    background-color: rgba(0, 0, 0, .10);
}

.icon.is-danger {
    color: var(--error);
}

.tabs.is-toggle li.is-active a {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: var(--white);
    z-index: 1;
}



/* component styles */
.box.is-draft {
    border-radius: 12px;
}

.button {
    border-radius: 10px;
    min-height: 40px;
}

.button.is-secondary {
    border-radius: 10px !important;
    min-height: 40px;
}

.label {
    padding-left: 4px;
    padding-top: 10px;
    padding-bottom: 7px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.input, .select {
    min-height: 44px;
    border-radius: 8px;
}

.textarea {
    border-radius: 8px;
}

.table td {
    vertical-align: middle;
}

.modal-content {
    padding: 24px;
    border-radius: 12px;
    max-width: 480px;
}

.select, .dropdown-container select {
    max-width: 100%;
    width: 100%;
    background-color: #ffffff;
    border-color: #dbdbdb;
    color: #363636;
    align-items: center;
    display: inline-flex;
    height: 2.5em;
    justify-content: flex-start;
    padding-bottom: calc(.5em - 1px);
    padding-left: calc(.75em - 1px);
    padding-right: calc(.75em - 1px);
    padding-top: calc(.5em - 1px);
    position: relative;
    vertical-align: top;
}

.modal-close:hover {
    border-radius: 6px;
}

.tabs.is-toggle {
    margin-bottom: 40px;
}

.tabs.is-toggle ul {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none;
}
  
.tabs.is-toggle li {
    flex: 1;
}
  
.tabs.is-toggle li a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.file-cta {
    border-radius: 10px !important;
    border-color: var(--color-primary);
    background-color: var(--white);
    color: var(--color-primary);
    min-height: 40px;
}

.file-cta:hover {
    background-color: var(--color-primary-light) !important;
    color: var(--color-primary-hover) !important;
}

.block {
    margin-bottom: 8px;
}

.upload-container {
    padding: 24px;
    border: 1px dashed #b6b6b6;
    border-radius: 10px;
    margin: 20px 0px;
    background-color: var(--white) ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* START of styles for the progress bar in drafters */
.tabs.is-toggle.is-progress {
    display: flex;
    justify-content: center; /* Centers the <ul> horizontally */
    align-items: center; /* Centers the <ul> vertically */
    padding-bottom: 20px;
    margin-bottom: 36px;
    border-bottom: rgba(0, 0, 0, .10);
    border-width: 0px 0px 1px 0px;
    border-style: solid;
}

.tabs.is-toggle.is-progress ul {
    gap: 0.4rem;
    flex-grow: 0;
}

.tabs.is-toggle.is-progress ul li {
    display: inline-flex; /* Change display to inline-flex to hug content */
    align-items: center; /* Center align items vertically */
    position: relative;
    padding-right: 1.3rem; /* Space out the lines */
}

.tabs.is-toggle.is-progress ul li::after {
    content: "";
    display: block;
    width: 1rem; /* Width of the line */
    height: 2.4px; /* Full height of the <li> */
    background: #ccc; /* line color */
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

/* Media query for viewports 1400px and wider */
@media (min-width: 1500px) {
    .tabs.is-toggle.is-progress ul {
        gap: 0.5rem;
    }
    
    .tabs.is-toggle.is-progress ul li {
        padding-right: 1.7rem; /* Space out the lines */
    }
    
    .tabs.is-toggle.is-progress ul li::after {
        width: 1.2rem; /* Increased width of the line */
    }
}
@media (min-width: 1920px) {
    .tabs.is-toggle.is-progress ul {
        gap: 0.8rem;
    }
    
    .tabs.is-toggle.is-progress ul li {
        padding-right: 3.3rem; /* Space out the lines */
    }
    
    .tabs.is-toggle.is-progress ul li::after {
        width: 2.5rem; /* Increased width of the line */
    }
}

.tabs.is-toggle.is-progress a {
    border-style: hidden;

}

.tabs.is-toggle.is-progress ul li:last-child::after {
    content: none; /* Remove the line after the last <li> */
}

.tabs.is-toggle.is-progress ul li:last-child {
    padding-right: 0px; /* Remove last child's padding*/
}

.tabs.is-toggle.is-progress li a {
    border-radius: 8px; /* Border radius for each tag */
}

.tabs.is-toggle.is-progress li:first-child a {
    border-radius: 8px; /* Border radius for first tag */
}

.tabs.is-toggle.is-progress li:last-child a {
    border-radius: 8px; /* Border radius for last tag */
}
/* END of styles for the progress bar in drafters */

.button .icon:first-child:not(:last-child) {
    margin-left: -4px;
}

/* Interactions */

.no-hover:hover {
    background-color: transparent !important;
    cursor: default !important;
}